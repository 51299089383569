<template>
  <div class="mt-2">
    <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
      Back
    </button>
    <div class="card shadow w-100 p-4 pb-6">
      <div class="row mb-4 text-center">
        <div class="col-md-12">
          <h3>  Detail for {{name}}</h3>
        </div>
      </div>
      
      <div class="row mt-4 mb-4">
        <div class="col-md-12">
          <h3>Selected Fields</h3>
          <hr class="my-1 " />
        </div>
      </div>
      <div class="row">
        <loader v-if="!data_loaded" />
      </div>


      <div class="row mb-4">
        <div
          class="col-md-6 mb-3"
          v-for="(fl, index) in all_fields"
          :key="index"
        >
          <label class="form-control-label"
            >{{ fl.named }} ({{ fl.type }}) </label
          >

         
        </div>
      </div>

      
    </div>
  </div>
</template>
<script>
import {  getDoc,doc,collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";
import IndependentLoader from "../../components/IndependentDefaultLoader";

export default {
  data() {
    return {
      selected: 0,

      name: "",
      nameError: "",
      all_fields: [],
      fieldsSelected: [],
      data_loaded: false,
      // button styles
      styleObject: {
        cursor: "not-allowed",
      },
      btnTitle: "Add Service Catalog",
      show_loader: false,
      classes: "btn btn-md btn-default my-2 px-4",
    };
  },
    components: {
    loader: IndependentLoader,
  },
  methods: {
    create_service_catalog: function() {
      this.show_loader = true;
      // Loop through all fields and keep their object
      // in a single array of selected fields (fields selected)
      this.all_fields.forEach((fi) => {
        if (fi.selected === true) {
          this.fieldsSelected.push(fi);
        }
      });
      // console.log(this.fieldsSelected)
      if (this.name == "") {
        this.show_loader = false;
        this.nameError = "the name is required";
      } else {
        this.nameError = "";
        const colRef = collection(db, "serviceCatalog");
        addDoc(colRef, {
          fields: this.fieldsSelected,
          name: this.name,
        })
          .then(() => {
            alert.success_center("Service Catalog Added successfully.");
            this.show_loader = false;
            this.$router.push({ name: "Service Catalogs" });
          })
          .catch((e) => {
            this.show_loader = false;
            // TODO: add error method when update fail
            alert.error("An error occured");
            console.log(e);
          });
      }
    },
    fetch_fields: function() {

    let parentUrl = `serviceCatalog`;
       const ref = doc(db, parentUrl, this.$route.params.serviceCatalogUuid);
        
      getDoc(ref)
        .then((snapShots) => {
         
           this.name=snapShots.data()['name']; 
          let fields = snapShots.data()['fields'];
          
            fields.forEach((document) => {
            
            this.all_fields.push(document);
          });
          
        })
        .then(() => {
          this.data_loaded = true;
        })
        .catch(() => {
          this.data_loaded = false;
          alert.error("an error occured! please try again.");
        });
    },
  },

  created() {
    this.fetch_fields();
  },
};
</script>

<style lang="scss" scoped></style>
